#message-contents{
    padding: 2rem;
}

#messages-left-container{
    width: 30vw;
    min-height: 400px;
    height: 85vh;
    background-color: white;
    border-right: 1px solid #f1f1f1 ;
    overflow: scroll;

}

#messages-right-container{
    width: calc(100% - 32vw);
    background-color: white;
    height: 85vh;
    position: relative;
    padding: 1rem;
    padding-bottom: 0 ;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

#msg-box{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    border-top: 1px solid #f1f1f1;
    padding: 1rem;
    display: flex;
    align-items: center;
    background-color: #fff;
}

.msg-avatar{
    height: 56px;
    width: 56px;
    border-radius: 28px;
    background-color: #1e1e1e;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    flex-shrink: 0;
}

.msg-avatar-sml{
    height: 38px;
    width: 38px;
    border-radius: 19px;
    background-color: #1e1e1e;
    color: white;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
}

.msg-snapshot{
    height: 100px;
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: left;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
    overflow: hidden;
}

.msg-snapshot:hover{
    cursor: pointer;
}

#whole-chat{
    height: calc(100% - 100px);
    width: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
}

.other-sender-msg{
    background-color: #f1f1f1;
    border-radius: 12px;
    border-top-left-radius: 0;
    width: 60%;
    min-height: 80px;
    margin: 1rem 0;
    padding: 1rem;

}

.my-msg{
    border: 2px solid #f1f1f1;
    border-radius: 12px;
    border-top-right-radius: 0;
    width: 60%;
    min-height: 80px;
    margin: 1rem 0;
    padding: 1rem;
}