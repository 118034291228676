*{
  box-sizing: border-box;
  letter-spacing: 1px;
  margin: 0;
}

p, input, select, textarea, a {
  font-family: 'Satoshi', sans-serif;
  letter-spacing: -0.5px;
}

button:hover{
  cursor: pointer;
}

a{
  text-decoration: none;
}

.outlet{
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 0;
}

.gradient-container{
  padding: 2rem;
  width: 100%;
  min-height: 150px;
  border-radius: 8px;
  background: linear-gradient(to right, #a28f89, #e0dad7  ) ;
  margin: 10px 0;
}

.primary-btn{
  background-color: #e0dad7;
  color: white;
  height: 48px;
  border-radius: 8px;
  border: unset;
  margin: 1rem 0;
  font-family: 'Satoshi', sans-serif;
  font-size: 18px;
  transition: 0.6s;
  padding: 0 15px;
  color: #272838;
  letter-spacing: -0.5px;
  max-width: 90%;
}

.secondary-btn{
  background-color: #fff;
  color: blue;
  height: 50px;
  border-radius: 25px;
  border: unset;
  width: 350px;
  margin: 1rem;
  font-size: 16px;
  transition: 0.6s;

}


.disabled-btn{
  background-color: #f5f5f5;
  color: grey;
  height: 50px;
  border-radius: 25px;
  border: unset;
  width: 350px;
  margin: 1rem;
  font-size: 16px;
  transition: 0.6s;
}

.disabled-btn:hover{
  cursor: not-allowed;
}

.primary-btn:hover, .secondary-btn:hover{
  cursor: pointer;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.om-row{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.om-input{
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  background-color: #fdfdfd;
  height: 48px;
  padding-left: 10px;
  margin: 1rem 0;

}

.om-header{
  font-family: 'Satoshi', sans-serif;
  font-size: 32px;
  letter-spacing: -0.5px;
  color: #272838;
  font-weight: 400;
}

.om-subheader{
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  letter-spacing: -0.5px;
  color: #272838;
  font-weight: 400;
  margin: 1rem 0;
  opacity: 0.5;
}

.tab-container{
    height: 68px;
    border: 0.4px solid #d4d4d4;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    margin: 1rem 0;
}

.tab-active{
  height: 50px;
  padding:   1rem;
  background-color: #e0dad7;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  border-radius: 4px;
}

.tab{
  height: 50px;
  padding:   1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}

.tab-active:hover, .tab:hover{
  cursor: pointer;
}

.tab-square{
  height: 30px;
  width: 30px;
  border: 1px solid rgb(190, 190, 190);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

#blur-overlay{
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(15px);
  background-color: rgba(149, 157, 165, 0.5);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.blur-info-container{
  background-color: white;
  width: 635px;
  height: 668px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  max-width: 90vw;
  max-height: 90vh;
  overflow: scroll;
  text-align: left;
}

.transparent-btn{
  background-color: unset;
  border: unset;
  margin: 0 10px;
  text-decoration: underline;
  font-family: 'Inter', sans-serif;
  font-size: 20px;

}

.sml-btn{
  border: unset;
  border-radius: 5px;
  padding: 8px;
  background-color: #a28f89;
  color: white;
}

.hover:hover{
  cursor: pointer;
}

/* Fonts */

 @font-face {
  font-family: "Satoshi";
  src: local("Satoshi"),
   url("./Assets/Fonts/Satoshi-Medium.otf") format("opentype");
 }

 @font-face {
  font-family: "Arual";
  src: local("Arual"),
   url("./Assets/Fonts/Arual.ttf") format("truetype");
 }