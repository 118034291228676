#teacher-dash-content{
    padding: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

#teacher-list-container{
    border: 1px solid #f1f1f1;
    border-radius: 8px;
    width: 100%;
   
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    overflow: scroll;
  
   
}

.teacher-snapshot{
    height: 100px;
    width: 30%;
    min-width: 275px;
    max-width: 400px;
    padding: 1rem;
    margin: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-radius: 8px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}