#timetable-dash-contents{
    padding: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

#timetable-container{
    height: 75vh;
    min-height: 600px;
    width: 100%;
    border-radius: 8px;
    padding: 1rem;
    border: 1px solid #f1f1f1;
    overflow: scroll;
}

#edit-title{
    height: 38px;
    margin: 5px 10px 5px 0;
    font-size: 32px;
    border: 2px solid #a28f89;
    border-radius: 8px;

}

.unassigned-select{
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    padding: 5px;
}

/*Create timetable*/


.staff-name-container{
    width: 100%;
    border: solid 2px grey;
    margin: 1rem 0;
    border-radius: 3px;
    padding: 0.3rem 1rem;
}
.staff-name-container:hover, .staff-name-selected:hover{
    cursor: pointer;
}

.staff-name-selected{
    width: 100%;
    border: solid 2px green;
    margin: 1rem 0;
    border-radius: 3px;
    padding: 0.3rem 1rem;
}

.staff-name-selected > *{
    color: green;
    font-weight: bold;
}

.rota-staff-img{
    height: 50px;
    width: 50px;
    border-radius: 25px;
    object-position: center;
    object-fit: cover;
    margin-right: 1rem;
}
.date-cell{
    margin: 5px 0;
    height: 100px;
    min-width: 150px;
    border: 1px solid #e0dad7;
}

#rota-table th, #rota-table td{
    padding: 0;

}

#rota-table {
    overflow-x: scroll;
}

#rota-table td:first-child {
    position: sticky;
    left: 1rem;
    z-index: 2;
  }


#rota-table thead tr:nth-child(1) th {
    padding: 1rem;
    position: sticky;
  
    z-index: 3;
    background: #e0dad7;
  }

 


.rota-staff-table-container{
    background-color: #e0dad7;
    min-width: 250px;
    margin: 0.5rem;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    display: flex;
    align-items: center;
}


#side-modal{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    backdrop-filter: blur(5px);
    background-color: rgba(149, 157, 165, 0.5);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

#side-modal-container{
    position: absolute;
    right: 2rem;
    top: 2rem;
    height: 92vh;
    min-height: 600px;
    width: 612px;
    max-width: 90vw;
    background-color: #fefcfc;
    border-radius: 22px;
    padding-top: 75px;
}

#close-contest-btn{
    height: 42px;
    width: 42px;
    border-radius: 24px;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 999;
    border: 2px solid #d4d4d4;
    background-color: unset;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.side-modal-header{
    width: 100%;
    padding: 20px 50px;
    border-top: 2px solid #f1f1f1;
    border-bottom: 2px solid #f1f1f1;

}

.new-contest-container{
    background-color: #fff;
    width: 90%;
    margin: 1rem auto;
    border: 1px solid #f1f1f1;
    border-radius: 8px;
    padding: 1rem;
    overflow: scroll;
    height: 75%;
}

.modal-input{
    width: 100%;
    height: 52px ;
    padding: 0 10px;
    border: 0.4px solid #d4d4d7;
    border-radius: 8px;
    font-size: 16px;
}

.timetable-list{
    border-collapse:collapse
}

.timetable-list td, .timetable-list th{
    padding: 10px;
    min-width: 200px;
    height: 48px;
    text-align: left;

}

.timetable-list tbody tr:nth-child(odd){
    background-color: #e0dad7;
}

.timetable-list tr:hover{
    cursor: pointer;
}

#import-btn{
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border: unset;
    border-radius: 8px;
    color: white;
    background-color: #a28f89;
    font-family: "Satoshi";
    margin: 1rem 0;
}