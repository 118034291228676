

#business-dash-content{
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 80%;
    padding: 2rem;
}

#create-template-box{
    width: 100%;
    height: 215px;
    background-color: rgb(224, 218, 215);
    border: 2px solid rgb(224, 218, 215);
    border-radius: 8px;
    padding: 2rem;
    margin: 2rem 0;
}

#create-template-btn{
    height: 48px;
    width: 171px;
    background-color: #f1f1f1;
    border: unset;
    border-radius: 8px;
    font-family: 'Satoshi', sans-serif;
    font-size: 20px;
    letter-spacing: -0.5px;
    margin-top: 1rem;
}

#business-dash-tabs{
    width: 100%;
    height: 74px;
    border: 0.4px solid #d4d4d4;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    overflow: scroll;
}

.business-dash-tab-active{
    height: 50px;
    padding:   1rem;
    background-color: #d6d6d6;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
}

.business-dash-tab{
    height: 50px;
    padding:   1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
}

.business-dash-tab-active:hover, .business-dash-tab:hover{
    cursor: pointer;
}

#dash-calendar{
    padding: 2rem;
    width: 100%;
    min-height: 400px;
    border-radius: 8px;
    background-color: #fff;
    /*background: linear-gradient(to right, #ffffff, rgb(224, 218, 215)) ;*/
    border: 1px solid #d9d9d9;
    margin: 10px 0;
}

.tab-content-header{
    font-size: 11px;
    font-family: 'Inter', sans-serif;
    color: grey;
    line-height: 1.5rem;
}

.tab-content-title{
    color: #272838;
    font-family: 'Satoshi', sans-serif;
    font-size: 20px;
    letter-spacing: -0.5px;
    
}

.tab-content-btn{
    height: 29px;
    width: 122px;
    background-color: #f1f1f1;
    border: unset;
    border-radius: 8px;
    font-family: 'Satoshi', sans-serif;
    font-size: 14px;
    letter-spacing: -0.5px;
    margin-top: 2rem;

}

#top-freelance-scroller{
    width: 100%;
    height: 404px;
    background-color: #fff;
    border: 2px solid #f1f1f1;
    border-radius: 4px;
    margin: 2rem 0;
    overflow: scroll;
    display: flex;
    padding: 2rem;

}

.freelance-overview-container{
    border-right: 1px solid #dfdfdf;
    width: 300px;
    flex-shrink: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

#more-candidates-btn{
    width: 100%;
    height: 48px;
    border: unset;
    background-color: #f1f1f1;
    border-radius: 8px;
    font-family: 'Satoshi', sans-serif;
    font-size: 20px;
    letter-spacing: -0.5px;
}

.send-msg-btn{
    width: 147px;
    height: 35px;
    background-color: #f1f1f1;
    border: unset;
    border-radius: 8px;
    color: #272838;
    font-family: 'Satoshi', sans-serif;
    font-size: 16px;
    letter-spacing: -0.5px;
}

.freelance-overview-img{
    height: 60px;
    width: 60px;
    border: unset;
    border-radius: 8px;
    background-color: #f1f1f1;
    object-fit: cover;
    object-position: center;

}

#popular-jobs-container{
    height: 45vh;
    min-height: 500px;
    width: 100%;
    overflow: scroll;
    border: 2px solid #f1f1f1;
    border-radius: 8px;
    margin: 2rem 0;
    position: relative;
}

.popular-job{
    width: calc(100% - 2rem);
    height: 176px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin: 1rem;
    padding: 2rem;
    overflow: hidden;

}

.job-chip{
    background-color: #f1f1f1;
    border-radius: 8px;
    margin: 0 5px;
    padding: 5px 8px;

}
.job-chip p {
    font-size: 12px;
    letter-spacing: -0.5px;
}

#see-jobs-btn{
    height: 46px;
    width: 174px;
    border-radius: 8px;
    background-color: #f1f1f1;
    border: unset;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    z-index: 999;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-family: 'Satoshi', sans-serif;
    font-size: 18px;
    color: #272838;
    letter-spacing: -0.5px;
}

/* Sidebar styling */

#business-dash-sidebar{
    position: absolute;
    right: 0;
    top: 0;
    padding: 2rem;
    width: 20vw;
    
}

#notifications-container{
    width: 100%;
    height: 45vh;
    background-color: white;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    padding: 1.5rem;
}

#notifications-scroller, #message-scroller{
    overflow-y: scroll;
    height: 60%;
    margin: 2rem 0;
    padding: 3px;

}

.notification-preview{
    height: 79px;
    width: 287px;
    background-color: #fbfbfb;
    border: 2px solid #f1f1f1;
    border-radius: 4px;
    margin: 5px 0;
}

#bus-message-container{
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    height: 45vh;
    width: 100%;
    margin-top: 2rem;
    padding: 2rem 0;
    overflow: visible;
}

#go-to-msgs{
    height: 35px;
    width: 147px;
    background-color: #f1f1f1;
    border: unset;
    font-family: 'Satoshi', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    border-radius: 8px;
    color: #272838;
}

.msg-preview{
    height: 77px;
    width: 100%;
    margin: 10px 0;
    padding: 1rem;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;   
    border-radius: 4px; 
    display: flex;
}

.msg-preview:hover{
    cursor: pointer;
}

.calendar-event{
    background-color: #a28f89;
    border-color: #a28f89;
    color: white;
    padding: 5px;
}

@media only screen and (max-width: 1250px) {
    #business-dash-sidebar{
        display: none;
        
    }
    #business-dash-content{
        width: 100%;
    }
  }