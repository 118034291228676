#classes-container{
    border: 1px solid #f1f1f1;
    border-radius: 8px;
    padding: 2rem;
    display: flex;
    overflow: scroll;
}

.cell-no-data{
    padding: 10px;
    width: 150px;
    height: 110px;
    background-color: #f1f1f1;
    text-align: center;
    font-family: "Satoshi";
    color: grey;
    flex-shrink: 0;

}

.cell-with-data{
    padding: 10px;
    width: 150px;
    height: 110px;
    background-color: #a28f89;
    text-align: center;
    color: white;
    font-family: "Satoshi";
    flex-shrink: 0;
  
}

.cell-with-data:hover{
    cursor: pointer;
}

.cell-no-data:hover{
    cursor: not-allowed;
}